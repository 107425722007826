import { useEffect, useState } from "react";
const useIsMount = () => {
  const [isMount, setIsMount] = useState(true);
  useEffect(() => {
    setIsMount(false);
    return () => {
      setIsMount(true);
    };
  }, []);
  return isMount;
};
export default useIsMount;