const randomIndex = <T extends { length: number }>({ length }: T): number =>
  Math.floor(Math.random() * length);

export default randomIndex;

export const pushRandomIndices = <T>(array: T[], values: T | T[]): T[] => {
  const valuesArray = Array.isArray(values) ? values : [values];

  for (const value of valuesArray) {
    const index = randomIndex(array);
    array.splice(index, 0, value);
  }

  return array;
};
