import useIsVisible from "@hooks/use-is-element-visible";
import useIsMount from "@hooks/use-is-mount";
import useIsWindowFocused from "@hooks/use-is-window-focused";
import { cn } from "@lib/utils";
import { EasingDefinition, motion, useAnimate } from "motion/react";
import React, { CSSProperties, forwardRef, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
type Orientation = "vertical" | "horizontal";
type RollingListProps = {
  children: ReactElement[];
  orientation?: Orientation;
  gap?: number;
  className?: string;
  skipAnimation?: boolean;
  ignoreOnBlur?: boolean;
};
const empty = [] as never[];
const isFirst = (i: number) => i === 0;
const isLast = (i: number, length: number) => i >= length - 1;
const getInitialStyles = (i: number) => ({
  opacity: +!isFirst(i),
  minWidth: "var(--min-width)",
  transform: "var(--transform)"
});
type Ref = {
  interval: number;
};
const ease: EasingDefinition = "easeOut";
const RollingList = forwardRef<Ref, RollingListProps>(({
  children = empty,
  orientation = "vertical",
  gap = 0,
  className,
  skipAnimation = false,
  ignoreOnBlur = true
}, ref) => {
  const isMount = useIsMount();
  const isWindowFocused = useIsWindowFocused();
  const isVertical = orientation === "vertical";
  const [scope, animate_] = useAnimate<HTMLDivElement>();
  const [observerRef, isVisible] = useIsVisible({
    threshold: 0
  });
  useEffect(() => {
    observerRef(scope.current);
  }, [observerRef, scope]);
  const getChildren = useCallback(() => {
    return Array.from(scope.current?.children || empty) as HTMLElement[];
  }, [scope]);
  const interval = ref && "current" in ref ? ref.current.interval : 1 / 4 * 1000;
  const duration = interval / 1000;
  const childrenLength = children?.length || 0;
  const trueGap = gap / childrenLength * (childrenLength - 1);
  const axis = orientation === "vertical" ? "y" : "x";
  const [hasAnimatedOnce, setHasAnimatedOnce] = useState(false);
  const transform = useMemo(() => [`calc(-100% - ${trueGap}px)`, 0] as const, [trueGap]);
  const {
    horizontalStyles,
    verticalStyles
  } = useMemo(() => {
    return {
      horizontalStyles: {
        "--min-width": `calc((100% / ${childrenLength - 1}) - ${trueGap}px)`,
        "--transform": `translateX(calc(-100% - ${trueGap}px))`,
        gap: `${gap}px`
      } as CSSProperties,
      verticalStyles: {
        "--min-width": `calc((100% / ${childrenLength - 1}) - ${trueGap}px)`,
        "--transform": `translateY(calc(-100% - ${trueGap}px))`,
        gap: `${gap}px`
      } as CSSProperties
    };
  }, [childrenLength, gap, trueGap]);
  const animate = useCallback(() => {
    const cs = getChildren();
    const timing = {
      duration,
      ease
    };
    const timings = {
      x: timing,
      y: timing,
      opacity: timing
    };
    const ps = cs.map((c, i) => {
      const opacity = [+(isFirst(i) ? 0 : 1), +(isLast(i, cs.length) ? 0 : 1)] as const;
      return animate_(c, {
        [axis]: transform,
        // @ts-expect-error not sure how to get this to work, good luck
        opacity
      }, timings);
    });
    return Promise.all(ps);
  }, [animate_, axis, duration, getChildren, transform]);
  const childrenKey = useMemo(() => children.map(c => c.key).join(","), [children]);
  useEffect(() => {
    if (skipAnimation || isMount) return;
    if (ignoreOnBlur && !isWindowFocused) return;
    if (!isVisible) return;
    animate();
    if (!hasAnimatedOnce) setHasAnimatedOnce(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenKey, skipAnimation]);
  if (!children?.length) return null;
  return <motion.div className={cn(
  // margin top to offset the negative margin on hoverin
  "relative flex mt-[5px]", orientation === "vertical" ? "flex-col" : "flex-row", className)} ref={scope} style={orientation === "vertical" ? verticalStyles : horizontalStyles}>
        {children.map((c, i) => <motion.div whileHover={{
      ...(orientation === "horizontal" && {
        marginTop: -5
      })
    }} transition={{
      duration: 1 / 6,
      ease: "easeInOut"
    }} key={c.key} style={getInitialStyles(i)} className={cn(!isVertical && isFirst(i) && !hasAnimatedOnce && "pointer-events-none", isLast(i - 1, children.length) && "pointer-events-none")}>
            {c}
          </motion.div>)}
      </motion.div>;
});
RollingList.displayName = "RollingList";
export default RollingList;