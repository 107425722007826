// implement useIsWindowFocused

import { useCallback, useEffect, useState } from "react";

const useIsWindowFocused = () => {
  const [isFocused, setIsFocused] = useState(
    typeof document !== "undefined" ? document.hasFocus() : false
  );

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [onBlur, onFocus]);

  return isFocused;
};

export default useIsWindowFocused;
