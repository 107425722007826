import { useState, useEffect, useCallback, RefCallback } from "react";

// workaround for type inference issues with IntersectionObserver
type intersectionObserverParams = ConstructorParameters<
  typeof IntersectionObserver
>;
type intersectionObserverOptions = intersectionObserverParams[1];

const useOnScreen = (
  options: intersectionObserverOptions = {}
): [RefCallback<HTMLElement>, boolean] => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  // Callback ref that will be attached to the target element
  const ref = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  useEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting !== isVisible) {
        setIsVisible(entry.isIntersecting);
      }
    }, options);

    observer.observe(element);

    return () => observer.disconnect();
  }, [element, options, isVisible]);

  return [ref, isVisible] as const;
};

export default useOnScreen;
