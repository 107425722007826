import { startTransition, useCallback, useEffect, useRef, useState } from "react";
import randomIndex from "./tools/array/randomIndex";
import exponentialDecay from "./tools/exponencialDecay";
import { GameHistory } from "@hooks/useSocket";
interface TopicMap<T> {
  change: T[];
  next: T;
}
type NextMethod<T> = (items: T[], ...args: unknown[]) => [next: T, index: number];
export const nextMethodMap = {
  random: <T,>(items: T[]) => {
    const index = randomIndex(items);
    return [items[index], index];
  },
  first: <T,>(items: T[]) => [items[0], 0],
  last: <T,>(items: T[]) => [items[items.length - 1], items.length - 1],
  differentGameHistory: (items: GameHistory[], lastID: GameHistory["game"]["id"]) => {
    let next = items[0];
    let index = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i].game.id !== lastID) {
        next = items[i];
        index = i;
        break;
      }
    }
    return [next, index];
  }
} satisfies Record<string, NextMethod<unknown>>;
export class Queue<T> extends EventTarget {
  private queue: T[] = [];
  public readonly intervalLimit = 500;
  private isRunning = false;
  public nextMethod: NextMethod<T> = nextMethodMap.first;
  private pointer = 0;
  constructor(nextMethod?: NextMethod<T>) {
    super();
    if (nextMethod) this.nextMethod = nextMethod;
  }
  get list() {
    return this.queue.slice(this.pointer);
  }
  get length() {
    return this.queue.length - this.pointer;
  }
  get interval() {
    const interval = Math.floor(exponentialDecay(this.intervalLimit, 0.025, this.length));
    return Math.max(Math.min(interval, this.intervalLimit), 0);
  }
  async next() {
    this.isRunning = true;
    if (this.pointer >= this.queue.length) {
      this.isRunning = false;
      this.queue = []; // Reset storage when exhausted
      this.pointer = 0;
      return;
    }
    const [next, index] = this.nextMethod(this.queue.slice(this.pointer));
    if (!next) return;
    this.pointer += index + 1; // Mark position instead of modifying array

    // Only clear when pointer reaches end
    if (this.pointer >= this.queue.length) {
      this.queue = [];
      this.pointer = 0;
    }
    this.publish("change", this.queue.slice(this.pointer));
    this.publish("next", next);
    if (this.interval < 17) {
      this.next();
      return;
    }
    setTimeout(() => {
      this.next();
    }, this.interval) as unknown as number;
  }
  add(...items: T[]) {
    queueMicrotask(() => {
      this.queue = [...items, ...this.queue];
      this.publish("change", this.queue);
      if (!this.isRunning) this.next();
    });
  }

  // eslint-disable-next-line no-unused-vars
  onChange(listener: (queue: T[]) => void) {
    const tk = this.subscribe("change", event => listener(event.detail));
    return tk;
  }

  // eslint-disable-next-line no-unused-vars
  onNext(listener: (next: T) => void) {
    const tk = this.subscribe("next", event => listener(event.detail));
    return tk;
  }
  destroy() {
    this.queue = [];
    this.isRunning = false;
  }
  subscribe<Topic extends keyof TopicMap<T>>(topic: Topic, callback: (event: CustomEvent<TopicMap<T>[Topic]>) => void) {
    this.addEventListener(topic as string, callback);
    return () => this.removeEventListener(topic as string, callback);
  }
  publish<Topic extends keyof TopicMap<T>>(topic: Topic, data: TopicMap<T>[Topic]): void {
    const event = new CustomEvent(topic as string, {
      detail: data
    });
    this.dispatchEvent(event);
  }
}
const defaultInitial = [];
type UseQueueProps<T> = {
  limit?: number;
  initial?: T[];
  resetsOnInitial?: boolean;
  nextMethod?: NextMethod<T>;
};
const prependToList = <T,>(prev: T[], e: T, limit: number) => {
  const newArr = new Array(Math.min(prev.length + 1, limit + 1));
  newArr[0] = e;
  for (let i = 1; i < newArr.length; i++) {
    newArr[i] = prev[i - 1];
  }
  return newArr;
};
const setIdFn = (prev: number[]) => [prev[0] + 1, ...prev];
export const useQueue = <T,>({
  limit = 10,
  initial = defaultInitial as T[],
  resetsOnInitial = false,
  nextMethod
}: UseQueueProps<T>) => {
  const ref = useRef(new Queue<T>(nextMethod));
  const queue = ref.current;
  const [list, setList] = useState(queue.list);
  const [output, setOutput] = useState<T[]>(initial);
  const [id, setId] = useState(initial.map((_, i) => i));
  useEffect(() => {
    const unsub = queue.onChange(setList);
    return unsub;
  }, [queue]);
  const onNext = useCallback((next?: T) => {
    if (!next) return;
    const setOutputFn = (prev: T[]) => prependToList(prev, next, limit);
    startTransition(() => {
      setOutput(setOutputFn);
      setId(setIdFn);
    });
  }, [limit]);
  useEffect(() => {
    const unsubNext = queue.onNext(onNext);
    return () => {
      unsubNext();
      queue.destroy();
    };
  }, [queue, onNext]);
  useEffect(() => {
    if (resetsOnInitial) {
      setOutput(initial);
      setId(initial.map((_, i) => i));
    }
  }, [initial, resetsOnInitial]);
  return {
    queue,
    list,
    output,
    id,
    ref
  };
};
export const useGameHistoryQueue = (opts: UseQueueProps<GameHistory>) => {
  const lastID = useRef<GameHistory["game"]["id"]>();
  const nextMethod: NextMethod<GameHistory> = useCallback(items => {
    const data = nextMethodMap.differentGameHistory(items, lastID.current);
    lastID.current = data?.[0]?.game?.id;
    return data;
  }, []);
  return useQueue({
    ...opts,
    nextMethod
  });
};

/**
 * @deprecated - for debugging purposes only
 */
export const useAddXNewResults = (list: GameHistory[], queue: Queue<GameHistory>) => useCallback(async (n = Math.floor(Math.random() * 10) + 1) => {
  const rng = crypto.getRandomValues(new Uint8Array(n));
  const randomIndexes = Array.from(rng).map(n => n % list.length);
  const newResults = randomIndexes.map(index => {
    const r = JSON.parse(JSON.stringify(list[index]));
    r.id += `#${crypto.randomUUID()}`;
    // multiplier should be between -5 and 5
    const newMultiplier = index % 2 === 0 ? 1.1 : 0.9;
    r.currencyPayout = r.currencyPayout * newMultiplier;
    r.multiplier = newMultiplier.toString();
    return r;
  });
  for (const r of newResults) {
    queueMicrotask(() => {
      queue.add(r);
    });
  }
}, [list, queue]);